import React, { useEffect, useState } from 'react';

import format from 'format-number';
import { useHistory } from 'react-router-dom';
import SupportContact from '../../../../partials/SupportContact';
import { useGlobalContext } from '../../../../GlobalContext';

import { formatDataShowrooms, calculPrice } from '../helpers';

import useDimension from '../../../../../customHooks/useDimension';

import shapeU from '../../../../../assets/images/shape-U.svg';
import shapeL from '../../../../../assets/images/shape-L.svg';
import shapeBack from '../../../../../assets/images/shape-back.svg';
import shapeOpen from '../../../../../assets/images/shape-open.svg';

import { createShowroom, updateShowroom } from '../../../../../actions/showroom';

const formatNumber = (number) => format({ integerSeparator: ' ' })(number);

export default function Recapitulatif(props) {
  const history = useHistory();
  const dimensionHook = useDimension();
  const [context, dispatch] = useGlobalContext();
  const { showRoomReducer } = context;
  const pricePerMeter = 50;

  const [validation, setValidation] = useState({});

  // ACTIONS
  const _createShowroom = (data) => createShowroom(dispatch, data);
  const _updateShowroom = (data, id) => updateShowroom(dispatch, data, id);

  // VALIDATE
  useEffect(() => {
    if (props.isLoading) return;

    const obj = {};
    if (
      !showRoomReducer.formData.option || !showRoomReducer.formData.option
      || !showRoomReducer.formData.option.shape
    ) {
      obj.shape = "Vous n'avez pas selectionné la forme de votre stand";
    }

    if (
      !showRoomReducer.formData.option || !showRoomReducer.formData.option
      || !showRoomReducer.formData.dimension
    ) {
      obj.dimension = "Vous n'avez pas indiqué les dimensions du stand";
    }

    setValidation(obj);
  }, [props.isLoading]);

  useEffect(() => {
    if (showRoomReducer.submitted) {
      history.push('/stand/submit-succeed');
    }
  }, [showRoomReducer.submitted]);

  function submit() {
    // UPDATE CREATE SHOWROOM
    const showroomData = formatDataShowrooms(showRoomReducer.formData);
    console.log(showroomData);

    if (!showRoomReducer.formData._id) {
      _createShowroom(showroomData);
    } else {
      _updateShowroom(showroomData, showRoomReducer.formData._id);
    }
  }

  function capitalize(val) {
    return val.charAt(0).toUpperCase() + val.slice(1);
  }

  function renderShapeImg(data) {
    if (!data.option || !data.option.shape) return <p className="warn">{validation.shape}</p>;
    let img;
    const { shape } = data.option;
    switch (shape) {
      case 'U':
        img = <img src={shapeU} alt="shape U"/>;
        break;
      case 'L':
        img = <img src={shapeL} alt="shape L"/>;
        break;
      case 'back':
        img = <img src={shapeBack} alt="shape back"/>;
        break;
      case 'open':
        img = <img src={shapeOpen} alt="shape open"/>;
        break;
      default:
        img = <img src={shapeU} alt="shape U"/>;
    }

    return img;
  }

  function renderDimension(data) {
    const { dimension } = showRoomReducer.formData;
    if (!dimension) return <p className="warn">{validation.dimension}</p>;

    let direction;
    if (showRoomReducer.formData.option
      && showRoomReducer.formData.option.direction
    ) {
      direction = showRoomReducer.formData.option.direction;
    }

    return (
      <>
        <div className="info-dimension">
          { showRoomReducer.formData.option
            && showRoomReducer.formData.option.customDimension
            ? <>
              <p>Dimension validée<br/> (en m) <span className="hide-on-mobile">:</span></p>
              <p className="custom-dimension">
                {showRoomReducer.formData.option.customDimension.L} x
                {` ${showRoomReducer.formData.option.customDimension.l}`}
              </p>
            </> : null
          }
          {direction === 'l'
            ? <>
              <p className="count-emplacement">{dimension / 6}</p>
              <p>

                {dimensionHook.width > 767
                  ? <>Emplacement{dimension / 6 > 1 ? 's' : ''} dos à dos :<br/></>
                  : <>Emplacement{dimension / 6 > 1 ? 's' : ''}<br/> Dos à dos :</>
                }
                3 x {(dimension / 6) * 2}m
              </p>
            </> : null
          }
          {direction === 'L'
            ? <>
              <p className="count-emplacement">{dimension / 6}</p>
              <p>
              {dimensionHook.width > 767
                ? <>Emplacement{dimension / 6 > 1 ? 's' : ''} côte à côte : <br/></>
                : <>Emplacement{dimension / 6 > 1 ? 's' : ''}<br/> Côte à côte : </>
              }
              <span className="size">{(dimension / 6) * 3} x 2m</span>
              </p>
            </> : null
          }
        </div>
      </>
    );
  }

  function renderSubmit() {
    return (
      <>
      { (Object.values(validation).length > 0)
        ? null
        : <div className="container-submit">
          <button onClick={submit}
            className={showRoomReducer.isLoading ? 'loading' : '' }
          >
            {showRoomReducer.formData._id ? 'Modifier mon stand' : 'Valider mon stand'}
            {showRoomReducer.isLoading
              ? <div className="loader"></div> : null
            }
          </button>
        </div>
      }
      </>

    );
  }

  return (
    <>
      <div className="step recapitulatif">
        <p className="section-title">Récapitulatif</p>
        <section>
          <div className="shape">
            {renderShapeImg(showRoomReducer.formData)}
          </div>
          <div className="dimension">
            <div className="shape-tablet">
              {renderShapeImg(showRoomReducer.formData)}
            </div>
            {renderDimension(showRoomReducer.formData)}
          </div>
          <div className="recap-options">
            {showRoomReducer.formData.isValid && (
              <h4>
                Votre stand a été validé et n'est plus modifiable, merci de nous contacter pour toute demande.
              </h4>
            )}
            { showRoomReducer.formData.name && (
              <p>Nom du stand: {showRoomReducer.formData.name}</p>
            )}
            { showRoomReducer.formData.option
              && showRoomReducer.formData.option.carpet
              ? <p>Moquette de base aux couleurs du salon</p> : null
            }
            { showRoomReducer.formData.option
              && !showRoomReducer.formData.option.carpet && showRoomReducer.formData.option.customCarpet
              ? <p>
                Vous apportez un sol personnalisé
              </p> : null
            }
            { showRoomReducer.formData.option
              && showRoomReducer.formData.option.countKit && showRoomReducer.formData.option.kit
              ? <p>
                {showRoomReducer.formData.option.countKit}
                {showRoomReducer.formData.option.countKit > 1
                  ? ' kits' : ' kit'
                } de mobilier
               </p> : null
            }
            { showRoomReducer.formData.option
              && !showRoomReducer.formData.option.countKit && showRoomReducer.formData.option.kit
              ? <p className="warn">
                Vous n'avez pas indiqué le nombre de kits mobilier.
               </p> : null
            }
            { showRoomReducer.formData.option
              && showRoomReducer.formData.option.power
              ? <p>
                Vous avez besoin de 20kVa. <span className="sub">(supplément 300€)</span>
              </p> : null
            }
            { showRoomReducer.formData.option
              && showRoomReducer.formData.option.storage
              ? <p>
                Vous avez besoin de {showRoomReducer.formData.option.storageSize}m² d'espace de stockage
                <span className="sub"> (supplément {`${showRoomReducer.formData.option.storageSize * pricePerMeter}€`})</span>
              </p> : null
            }
            { showRoomReducer.formData.option
              && showRoomReducer.formData.option.internet
              ? <p>
                Vous avez besoin d’une connexion internet filaire. <span className="sub">(supplément 300€)</span>
              </p> : null
            }
            <label className={'label-presta'}>BESOIN D’AMENAGER VOTRE ESPACE&nbsp;?</label>
            <div className="container-presta">
              <p className={'bold'}>Vous souhaitez louer des éléments complémentaires pour votre stand&nbsp;?</p>
              <p>Exemples&nbsp;: mobilier, éclairage, supports visuels...</p>
              <p>
                Effectuez directement vos choix sur&nbsp;:<br/>
                <a className="presta" target="_blank" href="https://www.prestations-supplementaires.fr/" rel="noreferrer">
                  https://www.prestations-supplementaires.fr/
                </a>
              </p>
            </div>

            {!showRoomReducer.formData.isValid && renderSubmit()}
          </div>
        </section>

      </div>
      <SupportContact />

    </>
  );
}
