import React from 'react';

import Block from './Block';
import SupportContact from '../../partials/SupportContact';
import Flash from '../../partials/Flash';

import ImgBus137 from '../../../assets/images/bus_137.jpg';
import ImgJoconde from '../../../assets/images/joconde.png';
import ImgCafet from '../../../assets/images/cafet.png';
import ImgResto from '../../../assets/images/resto.png';

export default function Restauration() {
  return (
    <>
      <div className="restaurations content large">
        <div className="fullwidth">
          <p>
            <b>SE RESTAURER PENDANT LE SALON</b>
          </p>
        </div>
        <div className="col">
          <Block
            title="La Petite Faim"
            image={ImgCafet}
          >
            <p>Un espace ouvert tout au long de la journée pour déjeuner sur le pouce,
               prendre un café, une pause gourmande.</p>
            <p>Le lieu d’échange et de convivialité.</p>
            <div className="new">
              <label>
                <b>Espace en mezzanine ouvert sur le salon</b>
              </label>
              <p>
                Restauration – pause gourmande<br />
                Espaces de détente<br />
                Échanges –co-working (prises de recharge à dispo)
              </p>

            </div>
          </Block>
          <Block
            title="Les Terrasses de l’Erdre"
            image={ImgResto}
          >
            <p>Buffets en fonction de la formule choisie de 24€ à 34€</p>
          </Block>
        </div>
        <div className="foodTrucks hide-on-tablet">
          <div className="header-trucks">
            <h2>Les Food Trucks</h2>
          </div>
          <div className='visual-truck'>
            <img src={ImgJoconde} alt="Le Joconde" />
          </div>
          <div className='visual-truck'>
            <Flash />
            <img src={ImgBus137} alt="Bus 137" />
          </div>
          <p className='bold'>Des places assises à votre disposition, couvertes ou en plein air&nbsp;!</p>
          <div className='foodTruck'>
            <h3>Le Joconde</h3>
            <p>Burger et frites maison pour les plus grands appétits !</p>
          </div>
          <div className='foodTruck'>
            <h3>
              Bus 137
            </h3>
            <p>
              Cuisine du monde
            </p>
          </div>
        </div>
        <div className="foodTrucks-mobile only-on-tablet">
          <div className="header-trucks">
            <h2>Les Food Trucks</h2>
            <p className='sub'>Des places assises à votre disposition, couvertes ou en plein air&nbsp;!</p>
          </div>
          <Block
            title="Le Joconde"
            image={ImgJoconde}
          >
            <p>Burger et frites maison pour les plus grands appétits !</p>
          </Block>
          <Block
            title="Bus 137"
            image={ImgBus137}
          >
            <p>Cuisine du monde</p>
          </Block>
        </div>
      </div>
      <div className="support content large">
        <SupportContact breakingPoint={900} />
      </div>
    </>
  );
}
