import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useGlobalContext } from '../../GlobalContext';
import { addAnimation, updateAnimation } from '../../../actions/animation';

import ListFiles from './ListFiles';
import SelectBoolean from '../../partials/form_fields/SelectBoolean';
import UploadFile from '../../partials/form_fields/UploadFile';
import Loader from '../../partials/Loader';

export default function EspaceAnimation(props) {
  const options = ['Oui', 'Non'];
  const [push, selectPush] = useState(true);

  const [data, setData] = useState();

  const [isDisabled, setIsDisabled] = useState(true);
  const [context, dispatch] = useGlobalContext();

  const { authReducer } = context;
  const { companyReducer } = context;

  const inputFilesRef = useRef();

  // ACTIONS
  const _addAnimation = (dataAnimation, origin) => addAnimation(dispatch, dataAnimation, origin);
  // eslint-disable-next-line max-len
  const _updateAnimation = (dataAnimation, origin) => updateAnimation(dispatch, dataAnimation, origin);

  useEffect(() => {
    if (!props.animation) return;
    selectPush(props.animation.push);
    setData(props.animation);
  }, [props.animation]);

  useEffect(() => {
    if (props.animationDone) {
      inputFilesRef.current.setFileName();
    }
  }, [props.animationDone]);

  useEffect(() => {
    if (!data) return;
    // if((data.description && data.description !== "") &&
    //   (data.timeslot && data.timeslot !== "")
    // ){
    // console.log(data.push);
    if ((data.description && data.description !== '')
      || data.push === false
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [data]);

  function handleChange(val, field) {
    const updatedData = { ...data };

    if (field === 'push') {
      selectPush(val);
    }

    _.set(updatedData, field, val);
    setData(updatedData);
  }

  function submit() {
    if (isDisabled) return;

    const formData = new FormData();

    formData.append('user', authReducer.user._id);
    formData.append('company', companyReducer.company._id);
    // formData.append('timeslot', data.timeslot);
    formData.append('description', data.description);
    formData.append('banner', data.banner);

    if (data.file) {
      Array.from(data.file).forEach((d) => {
        formData.append('file', d);
      });
    }

    if (data.push === undefined) {
      formData.append('push', true);
    } else {
      formData.append('push', data.push);
    }

    if (!props.animation) {
      _addAnimation(formData, 'base');
    } else {
      formData.append('id', props.animation._id);
      _updateAnimation(formData, 'base');
    }
  }

  return (
    <div className="container-formulaire">
      {props.isLoading
        ? <div className="overlay-loading">
          <Loader />
        </div> : null
      }
      <div>
        <p>
          Chacun de vos stands devra être animé
           : focus nouveautés, démonstration produits, technologie, formation, etc...
        </p>
        <p className="medium">
        Souhaitez-vous que votre animation soit relayée sur la plateforme,
         dans le module animations et conférences&nbsp;?<br />
        </p>
        <p>
          <span className="sub">Les produits concernés devront être mis en avant, théâtralisés, etc… et animés en continu sur la durée du salon.</span>
        </p>
        <SelectBoolean
          items={options}
          bool={push}
          handleChange={(e) => handleChange(e, 'push')}
        />
        <div className="separator"></div>
        {push
          && <>
            <p>Bannière visible pour les magasins : phrase d’accroche (120 caractères max)</p>
            <textarea
              rows="5" cols="63"
              maxLength={120}
              value={data ? data.banner : ''}
              onChange={(e) => handleChange(e.target.value, 'banner')}
            />
            <p>Merci de nous fournir visuels et/ou argumentaires à relayer en bannière.<span className="required">*</span></p>
            <textarea
              rows="5" cols="63"
              value={data ? data.description : ''}
              onChange={(e) => handleChange(e.target.value, 'description')}
            />
            <p>Vos propositions visuelles&nbsp;:</p>
            {(props.animation && props.animation.files && props.animation.files.length > 0) && <ListFiles files={props.animation.files} replace={`${props.animation._id}/`} />}
            <UploadFile ref={inputFilesRef} isMultiple={true} handleChange={(val) => handleChange(val, 'file')}>
              <p className="format">(pdf, jpg ou png)</p>
            </UploadFile>
          </>
        }
      </div>
      {((props.animation && props.animation.isValid !== 'valid') || !props.animation)
        && <div className="container-button">
          <button onClick={submit} className={isDisabled ? 'disabled' : ''}>
            {props.animation?._id
              ? 'Modifier' : 'Valider'
            }
          </button>
        </div>
      }
    </div>
  );
}
