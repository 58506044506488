import React, { useState, useEffect } from 'react';
// import ReactExport from 'react-export-excel';
import { NavLink } from 'react-router-dom';

import axios from 'axios';
import _ from 'lodash';
import { API_URL } from '../../../../actions';
import IconBtn from '../../../partials/IconBtn/IconBtn';
import ListProducts from './ListProducts';
import { useGlobalContext } from '../../../GlobalContext';
import { getProductsProvider } from '../../../../actions/products';
import { fnFormat } from '../../../../helpers/helpers.js';

/*const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;*/

export default function ProductsProvider({ history }) {
  const [context, dispatch] = useGlobalContext();
  const { authReducer } = context;
  const { productsReducer } = context;
  const { companyReducer } = context;
  const [products, setProducts] = useState();
  const [dataExcel, setDataExcel] = useState();

  const _getProductsProvider = (id) => getProductsProvider(dispatch, id);

  useEffect(() => {
    if (authReducer.user && authReducer.user.company) {
      setProducts();
      _getProductsProvider(authReducer.user.company);
    }
  }, [authReducer.user]);

  useEffect(() => {
    if (!companyReducer.company) return;
    async function getData() {
      const url = `${API_URL}/order/company/${companyReducer.company._id}/export`;

      const token = localStorage.getItem('token');

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const res = await axios.get(url, config);
      if (res.data) {
        setDataExcel(res.data.dataToExport);
      }
    }
    getData();
  }, [companyReducer.company]);

  useEffect(() => {
    if (!productsReducer.isLoading && productsReducer.products) {
      const products = productsReducer.products.filter((d) => d.orders.length > 0);
      const countedProducts = productsReducer.products.map((product) => {
        const obj = _.cloneDeep(product);
        let count = 0;
        product.orders.forEach((order) => {
          if (order.status === 'confirmed') {
            const foundProducts = order.products.filter((d) => d === product._id);
            count += foundProducts.length;
          }
        });
        obj.count = count;
        return obj;
      });
      setProducts(countedProducts);
    }
  }, [productsReducer.isLoading, productsReducer.products]);

  return (
    <>
      <div className="products-provider">
        <div className="content large">
          <div className="container-ca">
            {typeof productsReducer.ca === 'number'
              && <div className="title-ca">
                <p>Chiffres d'affaire :</p>
                <h2>{fnFormat(productsReducer.ca)}€</h2>
              </div>
            }
          </div>
          <div className="title-page">
            <h2>Mes produits</h2>
            {/* dataExcel &&
              <div className='btn-export'>
                <Download data={dataExcel} />
              </div>
            */}
            {/* <NavLink to="/edit-product-provider">
              <button>Ajouter un produit</button>
            </NavLink> */}
          </div>
          {(products && products.length > 0) && <ListProducts history={history} products={products} />}
        </div>
      </div>
    </>
  );
}

/*const Download = ({ data }) => {
  return (
    <ExcelFile filename={"commandes-validees.xlsx"} element={<IconBtn icon='export' label='Exporter les commandes validées' />}>
      {data.map((sheet,i) => (
        <ExcelSheet key={`sheet-${i}`} data={sheet.sheetData} name={sheet.sheetName}>
          {sheet.sheetHeader.map((col,j) => 
            <ExcelColumn key={`col-${i}-${j}`} label={col} value={col} />
          )}
        </ExcelSheet>
      ))}
    </ExcelFile>
  );
}*/
