import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../GlobalContext';
import useDimension from '../../../customHooks/useDimension';
import { getBadge } from '../../../actions/users';

import SupportContact from '../../partials/SupportContact';
import ModalSupportContact from '../../partials/ModalSupportContact';

import Slider from '../../partials/Slider/Slider';
import Tiles from '../../partials/Tiles/Tiles';
import Flash from '../../partials/Flash';
import Loader from '../../partials/Loader';

import Video from '../../../assets/videos/VIDEO_2025.webm';
import poster from '../../../assets/images/poster.png';

import IconTrophy from '../../../assets/images/trophy.png';
import IconQuizz from '../../../assets/images/quizz.svg';

import IconApp from '../../../assets/images/icons/icon-application.svg';
import PlanSalon from '../../../assets/images/plan-salon.png';
import PlanSalon2 from '../../../assets/images/plan-salon2.png';

export default function Salon(props) {
  const context = useGlobalContext()[0];
  const history = useHistory();
  const { authReducer } = context;
  const { companyReducer } = context;
  const { productsReducer } = context;
  const { exhibitionReducer } = context;
  const [tiles, setTiles] = useState();
  const [tilesProviders, setTilesProviders] = useState();
  const { exhibition } = exhibitionReducer;
  
  const dimension = useDimension();

  useEffect(() => {
    let orders = 'Mon panier';
    if (productsReducer.orders && productsReducer.orders.length) {
      orders = `Mon panier (${panierNumber(productsReducer.orders)})`;
    }

    // let tiles = [
    //   {title : "Informations<br/>pratiques", url : "/informations/", icon : "info"},
    //   {title : "Offres salon", url : "/products/all" , icon : "products"},
    //   {title : "Mon badge", url : "/" , icon : "badge"},
    //   {title : orders, url : "/orders" , icon : "panier"}
    // ]

    // {title : "Le plan", url : "/", icon : null, background : PlanSalon2,
    //   action : () => history.push('/plan')
    // },

    const tiles = [
      { title: 'Liste des exposants', url: '/list-providers', icon: 'stands' },
      { title: 'Animations et<br/>conférences', url: '/programme', icon: 'animations' },
      { title: 'Informations<br/>pratiques', url: '/informations/', icon: 'info' },
      {
        title: 'Le plan',
        url: '/',
        icon: null,
        background: PlanSalon2,
        action: () => history.push('/plan'),
      },
    ];

    if (companyReducer.company && companyReducer.company.domains.length > 0) {
      if (exhibition.offersActive) tiles.splice(2, 0, { title: 'Offres salon', url: '/products?page=1', icon: 'products' });
      // tiles.splice(3, 0, { title: orders, url: '/orders', icon: 'panier' });
    }

    const tilesProviders = [
      { title: 'Informations<br/>pratiques', url: '/informations/', icon: 'info' },
      {
        title: 'Le plan',
        url: '/',
        icon: null,
        background: PlanSalon2,
        action: () => history.push('/plan'),
      },
      // { title: "Questionnaire de<br/>satisfaction", url: "/avis", icon: "avis" }
    ];

    if (companyReducer.company && companyReducer.company.activity !== 'marque') {
      // tilesProviders.splice(3, 0, { title: 'Mes produits', url: '/products-provider', icon: 'panier' });
    }

    setTilesProviders(tilesProviders);
    setTiles(tiles);
  }, [productsReducer.orders]);

  function panierNumber(orders) {
    if (!orders) return;
    let numberColis = 0;

    const reducer = (accumulator, currentValue) => accumulator + parseInt(currentValue.packing);

    orders.forEach((order) => {
      const arrPacking = order.products.map((d) => (isNaN(parseInt(d.packing)) ? 1 : parseInt(d.packing)));
      const total = arrPacking.reduce((accumulator, currentValue) => accumulator + currentValue);
      numberColis += total;
    });

    if (numberColis > 0) {
      return numberColis;
    }
    return null;
  }

  const sliderItems = [
    <div className="slider-item trophy">
      <NavLink to="/trophy">
        <img src={IconTrophy} alt="trophée" />
        <p>Trophées du plus beau stand</p>
      </NavLink>
    </div>,
  ];

  //   <div className="slider-item quizz">
  //   <NavLink to="/quizz">
  //     <img src={IconQuizz} alt="quizz" />
  //     <p>Quiz</p>
  //   </NavLink>
  // </div>

  return (
    <>
      <ModalSupportContact />
      <div className="salon">
        <div className="content large">
          <div className="container-col">
            <div className="col">
              {(companyReducer.company && companyReducer.company.type === 'magasin' && dimension.width < 1024)
                && <div>
                  <Slider components={sliderItems} />
                  {tiles && <Tiles tiles={tiles} />}
                </div>
              }

              {(companyReducer.company && companyReducer.company.type === 'fournisseur' && dimension.width < 1024)
                && <div>
                  {tiles && <Tiles tiles={tilesProviders} />}
                </div>
              }

              {/*
              { (((companyReducer.company && companyReducer.company.type === "magasin") ||
                (companyReducer.company && companyReducer.company.type === "fournisseur")) &&
                dimension.width >= 1024) &&
            */}
              {((companyReducer.company && companyReducer.company.type === 'magasin')
                || (companyReducer.company && companyReducer.company.type === 'fournisseur'))
                && <div>
                  <h2>BTLec Ouest</h2>
                  <h1>Salon 2025</h1>
                  <p>L’événement annuel <b>INCONTOURNABLE</b> pour nos magasins et <b>TEMPS FORT</b> pour nos fournisseurs partenaires</p>
                  <ul>
                    <li className="dot">+ de 5000 m² d’exposition</li>
                    <li className="dot">+ de 100 fournisseurs présents</li>
                    <li className="dot">+ de 600 collaborateurs magasin présents</li>
                    <li className="dot">Des animations orientées sur l'expérience</li>
                    <li className="dot">Un espace conférence dédié pour la présentation de nouveautés, mini formations, intervenants marchés, etc...</li>
                    <li className="dot">Un moment d'échange autour d'un Cocktail Afterwork</li>
                  </ul>
                  <p>
                    Ne passez pas à côté de la vidéo ci-contre qui vous permettra de mieux appréhender les enjeux de ce salon.
                  </p>
                  <p>Adresse email de contact: <a href="mailto:salon@btlecouest.leclerc">salon@btlecouest.leclerc</a></p>
                  <div className="mobile-centered">
                    {authReducer.user && authReducer.user.role !== 'Member' && companyReducer.company.type === 'magasin'
                      ? <NavLink to={'/store/inscription'}>
                        <button>
                          Inscrire mon magasin
                        </button>
                      </NavLink> : null
                    }
                  </div>

                </div>
              }

            </div>

            {/* { (dimension.width >= 1024) && */}
            {true
              && <div className="col">
                <div className="video">
                  <video
                    controls
                    poster={poster}
                    muted
                    autoPlay
                    loop
                  >
                    <source src={Video} type="video/webm" />
                    Votre navigateur ne support pas les vidéos
                  </video>
                </div>
              </div>
            }

          </div>
          <SupportContact breakingPoint={900} />

        </div>
      </div>
    </>
  );
}
