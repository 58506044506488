import React, { useContext, useEffect } from 'react';
import ModalContext from '../ModalContext';
import IconBluePhone from '../../assets/images/icons/phone_blue_icon.svg';
import IconMailBlue from '../../assets/images/icons/mail_blue_icon.svg';
import IconPhone from '../../assets/images/icons/phone_icon.svg';

import useDimension from '../../customHooks/useDimension';

export default function SupportContact(props) {
  const modalContext = useContext(ModalContext);
  const dimension = useDimension();

  const breakingPoint = props.breakingPoint ? props.breakingPoint : 1124;

  useEffect(() => {
    if (dimension.width > breakingPoint && modalContext.isModalOpen) {
      modalContext.setIsModalOpen(false);
    }
  }, [dimension.width]);

  return (
    <div className="support-contact">
      {props.pageForm
        ? <p className="required"><span >*</span> données obligatoires</p> : null
      }
      { dimension.width < breakingPoint
        ? <button className="btn-blue" onClick={() => modalContext.setIsModalOpen(!modalContext.isModalOpen)}>
          <img src={IconPhone} alt="numéro de téléphone" />
        </button>
        : <>
          <h3>Une question ?</h3>
          <p>Contactez Célia Da Fonseca ou Sandrine Richard</p>
          <p className="phone">
            <img src={IconBluePhone} alt="numéro de téléphone" />
            <a href="tel:02-40-85-10-10">02 40 85 10 10</a>
          </p>
          <p className="email">
            <img src={IconMailBlue} alt="adresse email" />
            <a href="mailto:salon@btlecouest.leclerc">salon@btlecouest.leclerc</a>
          </p>
        </>
      }

    </div>
  );
}
