import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Switch from 'react-switch';
import { useGlobalContext } from '../../../GlobalContext';
import usePrevious from '../../../../customHooks/usePrevious';
import useDispatch from '../../../../customHooks/useDispatch';
import useDimension from '../../../../customHooks/useDimension';

import { updateCompanyUser, addCompanyUser, resetCompanyError } from '../../../../actions/company';
import InputField from '../../../partials/form_fields/InputField';
import SelectItems from '../../../partials/form_fields/SelectItems';
import IconCocktail from '../../../../assets/images/icons/icon-cocktail.svg';

export default function FormContributors({ user, isLoading, cancel }) {
  const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i;
  const [formData, setFormData] = useState({});
  const [isAfterworkChecked, setIsAfterworkChecked] = useState(false);
  const [selectedPresenceDays, setSelectedPresenceDays] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isEmailUsedByContributor, setIsEmailUsedByContributor] = useState(false);
  const [presenceDays, setPresenceDays] = useState();

  const [context, dispatch] = useGlobalContext();
  const { companyReducer } = context;

  const wasLoading = usePrevious(isLoading);
  const dimension = useDimension();

  const [isEmailError, setIsEmailError] = useState(false);

  // ACTIONS
  const _updateCompanyUser = (data, id, actionLoad) => updateCompanyUser(dispatch, data, id, actionLoad);
  const _addCompanyUser = (data) => addCompanyUser(dispatch, data);
  const _resetCompanyError = useDispatch(resetCompanyError, dispatch);

  // HANDLE ERROR ON EMAIL
  useEffect(() => {
    setIsEmailError(/email/.test(companyReducer.error));
  }, [companyReducer.error]);

  useEffect(() => {
    const days = companyReducer.companyList.days.filter((d) => d.value !== 'cocktail');
    const combineDays = { value: null, label: null, combined: true };
    days.forEach((day) => {
      combineDays.value = combineDays.value ? `${combineDays.value}/${day.value}` : day.value;
      combineDays.label = combineDays.label ? `${combineDays.label} et ${day.label}` : day.label;
    });
    setPresenceDays([
      combineDays,
      ...companyReducer.companyList.days,
      { label: 'Ne sera pas présent', value: false },
    ]);
  }, []);

  // RESET COMPANY ERROR WHEN UNMOUNT
  useEffect(
    () => () => {
      _resetCompanyError();
    }
    // eslint-disable-next-line
  , [])

  // INIT PRESENCE DAYS
  // IF THE ARE AN formData
  useEffect(() => {
    if (!user) return;

    const obj = {
      _id: user._id,
      email: user.email,
      profile: {
        lastName: user.profile.lastName,
        firstName: user.profile.firstName,
        function: user.profile.function,
        phone: user.profile.phone,
      },
      presence: user.presence,
    };

    if (user.presence) {
      obj.presence = user.presence;
      setSelectedPresenceDays(user.presence);
      setIsAfterworkChecked(user.presence.includes('cocktail'));
    }

    setFormData(obj);
    // eslint-disable-next-line
  }, [])

  // CLOSE FORM CONTACTS AFTER SUBMIT
  useEffect(() => {
    if (wasLoading && !isLoading) {
      cancel();
    }
  }, [isLoading]);

  // HANDLE IS READY TO SUBMIT
  useEffect(() => {
    if (formData
      && (formData.profile
        && (formData.profile.lastName && formData.profile.lastName !== '')
        && (formData.profile.firstName && formData.profile.firstName !== '')
        && (formData.profile.function && formData.profile.function !== '')
        && (formData.presence && formData.presence.length > 0)
      ) && (
      formData.email
        && emailReg.test(formData.email)
    )
      && !isEmailUsedByContributor
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [formData]);

  function handleChange(val, error, field) {
    const updatedData = { ...formData };

    if (field === 'email' && !error) {
      const found = companyReducer.companyUsers.find((user) => user.email === val && user.presence.length === 0);

      // USER IS A PROPS PASS TO THIS COMPOMENT TO
      // EDIT A USER
      let foundContributors;
      if (!user) {
        foundContributors = companyReducer.companyUsers.find((user) => user.email === val && user.presence.length > 0);
        setIsEmailUsedByContributor(!!foundContributors);
      }

      if (found && !foundContributors) {
        _.set(updatedData, '_id', found._id);
        _.set(updatedData, 'profile.lastName', found.profile.lastName);
        _.set(updatedData, 'profile.firstName', found.profile.firstName);
        _.set(updatedData, 'profile.function', found.profile.function);

        // setSelectedPresenceDays("Ne sera pas présent")
      } else {
        _.unset(updatedData, '_id');
      }
    } else if (field === 'email') {
      setIsEmailUsedByContributor(false);
    }

    if (field === 'presence' && val) {
      const found = presenceDays.find((day) => day.label === val);
      setSelectedPresenceDays([found.value]);
      if (isAfterworkChecked) {
        _.set(updatedData, field, [found.value, 'cocktail']);
      } else {
        _.set(updatedData, field, [found.value]);
      }
    } else {
      _.set(updatedData, field, val);
    }

    setFormData(updatedData);
  }

  function toggleAfterwork(val) {
    const updatedData = { ...formData };
    if (val) {
      _.set(updatedData, 'presence', [...updatedData.presence, 'cocktail']);
    } else {
      _.set(updatedData, 'presence', updatedData.presence.filter((d) => d !== 'cocktail'));
    }

    setFormData(updatedData);
    setIsAfterworkChecked(val);
  }

  function submit() {
    if (!isValid || companyReducer.isLoading || isEmailUsedByContributor) return;
    if (formData._id) {
      submitUdpateformData();
    } else {
      submitCreateformData();
    }
  }

  function submitUdpateformData() {
    const data = _.cloneDeep(formData);

    if (!data.presence) {
      data.presence = [];
    }

    data.presence = data.presence.filter((d) => !!d);

    const isPresentEveryDays = !!data.presence.find((d) => {
      const combined = presenceDays.find((day) => (day.value === d && day.combined));
      return combined;
    });

    if (isPresentEveryDays) {
      const combined = presenceDays.find((day) => day.combined);
      data.presence = data.presence.filter((d) => d !== combined.value);
      data.presence.push(...combined.value.split('/'));
    }

    _updateCompanyUser(data, formData._id, !user ? 'update-contributors' : null);
  }

  function submitCreateformData() {
    const data = _.cloneDeep(formData);
    data.company = companyReducer.company._id;

    if (!data.presence) {
      data.presence = [];
    }

    data.presence = data.presence.filter((d) => !!d);

    const isPresentEveryDays = !!data.presence.find((d) => {
      const combined = presenceDays.find((day) => (day.value === d && day.combined));
      return combined;
    });

    if (isPresentEveryDays) {
      const combined = presenceDays.find((day) => day.combined);
      data.presence = data.presence.filter((d) => d !== combined.value);
      data.presence.push(...combined.value.split('/'));
    }

    _addCompanyUser(data);
  }

  function getSelectedItems() {
    const found = presenceDays.filter((day) => selectedPresenceDays.includes(day.value));
    const combined = presenceDays.find((day) => day.combined);

    if (_.difference(combined.value.split('/'), selectedPresenceDays).length === 0) {
      let found = selectedPresenceDays.filter((d) => !combined.value.split('/').includes(d));
      found.push(combined.value);
      found = presenceDays.filter((day) => found.includes(day.value));
      return (found && found.length > 0) ? found.map((d) => d.label) : [];
    } else {
      const found = presenceDays.filter((day) => selectedPresenceDays.includes(day.value));
      return (found && found.length > 0) ? found.map((d) => d.label) : [];
    }
  }

  return (
    <MobileWrapper isMobile={dimension.width < 767}>
      <section className={dimension.width > 767 ? 'module-contact' : 'module-contact mobile'}>

        {dimension.width < 767
          ? <h2>
            Inscription d’un collaborateur
          </h2> : null
        }
        <div className="grid-form">
          <div className="container-email">
            <InputField
              id={'email'}
              value={formData.email ? formData.email : ''}
              title={'Email'}
              placeholder={''}
              type={'string'}
              format={'email'}
              required={true}
              disabled={false}
              handleChange={(val, error) => handleChange(val, error, 'email')}
            />
            {isEmailError && companyReducer.error
              ? <p className="warn">{companyReducer.error}</p> : null
            }
            {isEmailUsedByContributor
              && <p className="warn">Un collaborateur utilise déjà cette email</p>
            }
          </div>
          <InputField
            id={'lastName'}
            value={formData.profile ? formData.profile.lastName : ''}
            title={'Nom'}
            placeholder={''}
            type={'string'}
            textTransform={'uppercase'}
            accentCharacters={false}
            required={true}
            disabled={
              !emailReg.test(formData.email)
              || isEmailUsedByContributor
            }
            handleChange={(val, error) => handleChange(val, error, 'profile.lastName')}
          />
          <InputField
            id={'firstName'}
            value={formData.profile ? formData.profile.firstName : ''}
            title={'Prénom'}
            placeholder={''}
            type={'string'}
            textTransform={'uppercase'}
            accentCharacters={false}
            required={true}
            disabled={
              !emailReg.test(formData.email)
              || isEmailUsedByContributor
            }
            handleChange={(val, error) => handleChange(val, error, 'profile.firstName')}
          />
          <InputField
            id={'function'}
            value={formData.profile ? formData.profile.function : ''}
            title={'Fonction'}
            placeholder={''}
            type={'string'}
            textTransform={'uppercase'}
            required={true}
            disabled={
              !emailReg.test(formData.email)
              || isEmailUsedByContributor
            }
            maxLength={'30'}
            handleChange={(val, error) => handleChange(val, error, 'profile.function')}
          />
          <div className="container-phone">
            <InputField
              value={formData.profile ? formData.profile.phone : ''}
              title={'Téléphone'}
              placeholder={''}
              type={'string'}
              maskNumber={'## ## ## ## ##'}
              format={'phone'}
              handleChange={(val, error) => handleChange(val, error, 'profile.phone')}
            />
          </div>
        </div>
        <div className="container-input container-select-items">
          <label>Présence sur le salon <span className="required">*</span></label>
          {(presenceDays && presenceDays.length > 0)
            && <SelectItems
              items={presenceDays.filter((d) => d.label !== 'Cocktail').map((day) => day.label)}
              selectedItems={getSelectedItems()}
              required={true}
              uniq={true}
              handleChange={(val, error) => handleChange(val, error, 'presence')}
            />
          }
        </div>
        <div className="container-switch">
          <Switch
            onColor='#080'
            offColor='#FF0009'
            onChange={toggleAfterwork}
            checked={isAfterworkChecked}
          />
          <p>Présence au cocktail After Work du 14 mai à 17h30<span className="required">*</span><img key="cocktail selected" src={IconCocktail} alt="cocktail" /></p>
        </div>
        <div className="container-button">
          <button className="secondary" onClick={cancel}>Annuler</button>
          <button className={!isValid ? 'disabled' : ''} onClick={submit}>
            {formData && formData._id
              ? 'Modifier' : 'Ajouter'
            }
            {companyReducer.isLoading
              ? <div className="loader"></div> : null
            }
          </button>
        </div>
        {!isEmailError && companyReducer.error
          ? <p className="warn">{companyReducer.error}</p> : null
        }
      </section>
    </MobileWrapper>
  );
}

const MobileWrapper = ({ isMobile, wrapper, children }) => (
  isMobile
    ? <div className="container-mobile">
        <div className="overlay-module-contact"></div>
        {children}
      </div> : children
);
