import React from 'react';

import SupportContact from '../../partials/SupportContact';
import Block from './Block';

import IconMartini from '../../../assets/images/icons/icon-martini.svg';
import ImgCocktail from '../../../assets/images/picto_cocktail.png';

export default function Cocktail() {
  return (
    <>
      <div className="cocktail content large">
        <Block
              isOpen={true}
              title="Mercredi 14 mai 2025"
            >
              <div>
                <img src={ImgCocktail} alt="café" />
              </div>
                <div>
                  <img src={IconMartini} alt="conférence" />
                  <p>17h30-19H30<br/>Cocktail</p>
                </div>
              <div>
                <h3>
                  Inscrivez-vous au Cocktail after work…
                </h3>
                <p>Venez partager un moment de convivialité et d’échange
                   lors du cocktail after work qui se tiendra à
                    la sortie du Salon le mercredi 14/05. Equipes BT, Galec,
                     Expertise, collaborateurs magasins, fournisseurs partenaires&nbsp;:
                      le salon joue les prolongations en mode partage !</p>
                <p className="info">Pensez à bien vous inscrire.</p>
              </div>
            </Block>
      </div>
      <div className="support content large">
        <SupportContact breakingPoint={900}/>
      </div>
    </>
  );
}
