import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import LogoLeclerc from '../../assets/images/logo-leclerc.svg';
import LoginImg from '../../assets/images/login-img.png';
import LogoParcExpo from '../../assets/images/logo_parc_expo.svg';
import IconUser from '../../assets/images/icons/icon-user.svg';
import IconCaddy from '../../assets/images/icons/icon-shopping.svg';
import IconUserActive from '../../assets/images/icons/icon-user-active.svg';
import IconCaddyActive from '../../assets/images/icons/icon-shopping-active.svg';

import { useGlobalContext } from '../GlobalContext';
// import { resetAuth } from '../../actions/auth';
// import { resetCompany } from '../../actions/company';

export default function Login(props) {
  const [context] = useGlobalContext();
  const { authReducer } = context;

  // rulier.h@bakasable.fr
  // #B@k@s@bl3

  // ACTIONS
  // const _resetAuth = () => resetAuth(dispatch);
  // const _resetCompany = () => resetCompany(dispatch);

  useEffect(() => {
    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    if (
      id && token
      && authReducer.user
      && authReducer.user._id
    ) {
      props.history.push('/redirect');
    }
  }, [authReducer.user]);

  return (
    <div className="page select-type" >
      <div className="content">
        <div className="left">
          <img src={LoginImg} alt="Salon multimedia et electro" />
        </div>
        <div className="form-login">
          <img src={LogoLeclerc} alt="logo leclerc" />
          <h1>BTLec Ouest</h1>
          <h2>Salon Multimédia et Electro <br/>14/15&nbsp;mai 2025</h2>
          <div className="address">
            <img src={LogoParcExpo} alt="parc expo nantes" />
            <p>
              EXPO NANTES - Grand&nbsp;Palais<br/>
              Route de Saint-Joseph de Porterie<br/>
              44300 NANTES
            </p>
          </div>
          <div className="container-button">
            <NavLink to="/login">
              <button>
                <img src={IconUser} alt="Je suis un fournisseur" />
                <img src={IconUserActive} alt="Je suis un fournisseur" />
                Je suis un fournisseur
              </button>
            </NavLink>
            <NavLink to="/acdlec">
              <button>
                <img src={IconCaddy} alt="Je suis un magasin" />
                <img src={IconCaddyActive} alt="Je suis un magasin" />
                Je suis un magasin
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
