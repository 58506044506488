import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useGlobalContext } from '../../GlobalContext';
import { addAnimation, updateAnimation } from '../../../actions/animation';

import ListFiles from './ListFiles';
import SelectBoolean from '../../partials/form_fields/SelectBoolean';
import InputField from '../../partials/form_fields/InputField';
import UploadFile from '../../partials/form_fields/UploadFile';
import Loader from '../../partials/Loader';

export default function EspaceAnimation(props) {
  const options = ['Oui', 'Non'];
  const [option, selectOption] = useState();
  const [data, setData] = useState();

  const [isDisabled, setIsDisabled] = useState(true);
  const [context, dispatch] = useGlobalContext();

  const { authReducer } = context;
  const { companyReducer } = context;

  const inputFilesRef = useRef();

  // ACTIONS
  const _addAnimation = (dataAnimation, origin) => addAnimation(dispatch, dataAnimation, origin);
  // eslint-disable-next-line max-len
  const _updateAnimation = (dataAnimation, origin) => updateAnimation(dispatch, dataAnimation, origin);

  useEffect(() => {
    if (!props.animation) return;
    selectOption(true);
    setData(props.animation);
  }, [props.animation]);

  useEffect(() => {
    if (props.animationDone) {
      inputFilesRef.current.setFileName();
    }
  }, [props.animationDone]);

  useEffect(() => {
    if (!data) return;
    if ((data.description && data.description !== '')
      && (data.dimensionL !== '' && data.dimensionL > 0)
      && (data.dimensionP !== '' && data.dimensionP > 0)
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [data]);

  function handleChange(val, field) {
    const updatedData = { ...data };
    _.set(updatedData, field, val);
    setData(updatedData);
  }

  function submit() {
    if (isDisabled) return;
    const formData = new FormData();

    formData.append('user', authReducer.user._id);
    formData.append('company', companyReducer.company._id);
    formData.append('description', data.description);
    formData.append('mobilier', data.mobilier);
    formData.append('banner', data.banner);
    formData.append('dimensionL', data.dimensionL);
    formData.append('dimensionP', data.dimensionP);

    if (data.file) {
      Array.from(data.file).forEach((d) => {
        formData.append('file', d);
      });
    }

    if (!props.animation) {
      _addAnimation(formData, 'sup');
    } else {
      formData.append('id', props.animation._id);
      _updateAnimation(formData, 'sup');
    }
  }

  function toggleSelect(val) {
    selectOption(val);
  }

  return (
    <div className="container-formulaire">
      {props.isLoading
        ? <div className="overlay-loading">
          <Loader />
        </div> : null
      }
      <div>
        <p>
          Nos collaborateurs veulent une visite riche d’expériences et d’échanges.<br />
          Exemple : dégustation de café, démonstration culinaire, pôle gaming
           et réalité virtuelle, et pour lesquelles de vrais professionnels interviennent.
        </p>
        <p>
          Si vous êtes en mesure de proposer aux magasins ce type d’expérience, nous  vous mettons à disposition un espace en plus de votre stand de base sous certaines conditions.
        </p>
        <p>
          Cet espace nécessite la présence permanente sur les horaires du salon
           de formateurs ou professionnels, ressources non partagées avec votre stand.
            Le mobilier et la configuration de cet espace sont à votre charge.
        </p>
        <p>
          Pour ce faire, avez-vous besoin d’un espace complémentaire&nbsp;?<br />
        </p>
        <p>
          <span className="sub">Soumis à validation des équipes BTLec Ouest, sans surcoût.</span>
        </p>

        <SelectBoolean
          items={options}
          bool={option}
          handleChange={(val) => toggleSelect(val)}
        />

        {option
          ? <>
            <div className="separator"></div>
            <p>Bannière visible pour les magasins : phrase d’accroche (120 caractères max)</p>
            <textarea
              rows="5" cols="63"
              maxLength={120}
              value={data ? data.banner : ''}
              onChange={(e) => handleChange(e.target.value, 'banner')}
            />
            <p>Merci de décrire votre pôle d’expérience tel que cela sera indiqué pour les magasins &nbsp;:<span className="required">*</span></p>
            <p className="sub">La puissance fournie sur l’espace sera de 6KVA.
              En cas de besoins supplémentaires (20 KVA)
               merci de le préciser dans le texte ci-dessous.</p>
            <textarea
              rows="5" cols="63"
              value={data ? data.description : ''}
              onChange={(e) => handleChange(e.target.value, 'description')}
            />
            <p>
              <span className="medium">Dimensions souhaitées (LxP)<span className="required">*</span>&nbsp;:</span><br />
              Charge à vous de gérer le mobilier et la théâtralisation avec votre standiste.
            </p>
            <div className="container-dimension">
              <InputField
                id={'largeur'}
                title={''}
                value={data ? data.dimensionL : ''}
                placeholder={'Largeur'}
                type={'number'}
                loading={false}
                required={false}
                handleChange={(val) => handleChange(val, 'dimensionL')}
              />
              <div>x</div>
              <InputField
                id={'profondeur'}
                title={''}
                value={data ? data.dimensionP : ''}
                placeholder={'Profondeur'}
                type={'number'}
                loading={false}
                required={false}
                handleChange={(val) => handleChange(val, 'dimensionP')}
              />
              <p className="sub">Si votre proposition de Pôle d'Expérience est validée par BTLec Ouest, un espace dédié vous sera proposé en conséquent.</p>
              <p>Description du mobilier que vous apportez :</p>
              <textarea
                rows="5" cols="63"
                value={data ? data.mobilier : ''}
                onChange={(e) => handleChange(e.target.value, 'mobilier')}
              />
            </div>
            <p>Vos propositions visuelles&nbsp;:</p>
            {(props.animation && props.animation.files && props.animation.files.length > 0) && <ListFiles files={props.animation.files} replace={`${props.animation._id}/`} />}
            <UploadFile ref={inputFilesRef} isMultiple={true} handleChange={(val) => handleChange(val, 'file')}>
              <p className="format">(pdf, jpg ou png)</p>
            </UploadFile>
          </> : null
        }
      </div>

      {(((props.animation && props.animation.isValid !== 'valid') || !props.animation)
        && option
      )
        && <div className="container-button">
          <button onClick={submit} className={isDisabled ? 'disabled' : ''}>
            {props.animation?._id
              ? 'Modifier' : 'Valider'
            }
          </button>
        </div>
      }
    </div>
  );
}
