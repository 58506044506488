import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import InputField from '../partials/form_fields/InputField';
import selectStyle from '../partials/form_fields/selectStyle';

import LogoLeclerc from '../../assets/images/logo-leclerc.svg';
import LoginImg from '../../assets/images/login-img.png';
import IconLock from '../../assets/images/icons/lock_icon.svg';
import IconMail from '../../assets/images/icons/mail_grey_icon.svg';
import LogoParcExpo from '../../assets/images/logo_parc_expo.svg';

import { useGlobalContext } from '../GlobalContext';
import {
  signIn,
  resetError,
  // resetAuth,
} from '../../actions/auth';
// import { resetCompany } from '../../actions/company';

export default function Login(props) {
  const [context, dispatch] = useGlobalContext();
  const [loginData, setLoginData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [optionsCompanies, setOptionsCompanies] = useState();
  const [selectedCompany, setSelectedCompany] = useState();

  const { authReducer } = context;

  // ACTIONS
  const _signIn = (data) => signIn(dispatch, data);
  const _resetError = () => resetError(dispatch);
  // const _resetAuth = () => resetAuth(dispatch);
  // const _resetCompany = () => resetCompany(dispatch);

  useEffect(() => {
    if (loginData.email !== '' && loginData.password !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [loginData.email, loginData.password]);

  useEffect(() => {
    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    if (
      id && token
      && authReducer.user
      && authReducer.user._id
    ) {
      props.history.push('/redirect');
    }
  }, [authReducer.user]);

  useEffect(() => {
    if (!authReducer.companies) return;
    const options = [];
    authReducer.companies.forEach((company) => {
      options.push({ label: company.name, value: company.id });
    });
    setOptionsCompanies(options);
  }, [authReducer.companies]);

  // useEffect(()=>{
  //   localStorage.removeItem("id")
  //   localStorage.removeItem("token")
  //   localStorage.removeItem("company")
  //   _resetAuth()
  //   _resetCompany()
  // },[])

  useEffect(() => {
    if (authReducer.error) {
      const timer = setTimeout(() => {
        clearInterval(timer);
        _resetError();
      }, 2500);
      return () => {
        clearInterval(timer);
        _resetError();
      };
    }
  }, [authReducer.error]);

  function handleChange(val, field) {
    if (field === 'companies') {
      setSelectedCompany(val);
    }

    const obj = { ...loginData };
    obj[field] = val;
    setLoginData(obj);
  }

  function handlePressEnter(e) {
    if (e.key !== 'Enter') return;
    if (!disabled) {
      const data = _.cloneDeep(loginData);
      if (data.company) {
        data.company = loginData.company.value;
      }
      _signIn(data);
    }
  }

  function login() {
    const data = _.cloneDeep(loginData);
    if (data.company) {
      data.company = loginData.company.value;
    }
    _signIn(data);
  }

  return (
    <div className="page login" >
      <div className="content">
        <div className="left">
          <img src={LoginImg} alt="Salon multimedia et electro" />
        </div>
        <div className="form-login" onKeyPress={handlePressEnter}>
          <NavLink to="/">
            <img src={LogoLeclerc} alt="logo leclerc" />
            <h1>BTLec Ouest</h1>
            <h2>Salon Multimédia et Electro <br/>14/15&nbsp;mai 2025</h2>
          </NavLink>
          <div className="address">
            <img src={LogoParcExpo} alt="parc expo nantes" />
            <p>
              EXPO NANTES - Grand&nbsp;Palais<br/>
              Route de Saint-Joseph de Porterie<br/>
              44300 NANTES
            </p>
          </div>
          <div className="field-icon">
            <InputField
              value={''}
              placeholder={'Saissisez votre adresse email'}
              type={'email'}
              format={'email'}
              textTransform={'lowercase'}
              handleChange={(val) => handleChange(val, 'email')}
            />
            <img src={IconMail} alt="adresse email" />
          </div>
          <div className="field-icon">
            <InputField
              value={''}
              placeholder={'Saissisez votre mot de passe'}
              type={'password'}
              required={true}
              handleChange={(val) => handleChange(val, 'password')}
            />
            <img src={IconLock} alt="mot de passe" />
          </div>
          { optionsCompanies
            ? <div className="field-icon">
              <Select
                onChange={(val) => handleChange(val, 'company')}
                options={optionsCompanies}
                value={selectedCompany}
                isSearchable={false}
                styles={selectStyle}
              />
            </div> : null
          }
          <div className="centered">
            <button className={disabled ? 'disabled' : ''} onClick={login}>Connexion</button>

          </div>

          { authReducer.error
            ? <div className="centered">
              <p className="warn">Erreur d'authentification, veuillez vérifier vos identifiants.</p>
            </div> : null
          }

          {
            <NavLink to="/forgot-password">Mot de passe oublié ?</NavLink>
          }
        </div>
      </div>
    </div>
  );
}
